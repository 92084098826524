<template>
  <div class="container-center">
    <notifications :duration="3000" />
    <v-card elevation="10">
      <v-card-title>
        <v-row>
          <v-col cols="12" align="center">
            <v-img
              :src="require('@/assets/img/logo.jpeg')"
              width="60"
              alt="logo"
            />
            Entrar
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-form @submit.prevent="login" ref="form" lazy-validation>
              <v-text-field
                label="Usuário (email)"
                :prepend-icon="mdiAccount"
                :rules="[rules.required, rules.email]"
                v-model="email"
                ref="email"
                outlined
                dense
                class="lowercase"
              ></v-text-field>
              <v-text-field
                label="Senha"
                :type="mostrarSenha ? 'text' : 'password'"
                :rules="[rules.required]"
                :prepend-icon="mdiLock"
                v-model="password"
                ref="password"
                outlined
                :append-icon="mostrarSenha ? mdiEyeOutline : mdiEyeOffOutline"
                @click:append="toggleMostrarSenha"
                dense
                class="lowercase"
              ></v-text-field>

              <!-- <div id="links">
                <a href="#">Esqueci minha senha</a>

                <router-link to="/cadastro">Cadastre-se</router-link>
              </div> -->

              <v-btn
                :loading="getLoginLoading"
                :disabled="getLoginLoading"
                block
                depressed
                :dark="!getLoginLoading"
                color="primary"
                type="submit"
              >
                Entrar
              </v-btn>
              <!-- <v-btn block outlined color="blue" type="submit"> Login </v-btn> -->
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mdiAccount, mdiLock, mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
export default {
  name: "Login",

  data: () => ({
    rules: {
      required: (value) => !!value || "Obrigatório",
      counter: (value) => value.length <= 20 || "Max 20 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    email: "",
    password: "",
    mdiAccount: mdiAccount,
    mdiLock: mdiLock,
    mdiEyeOutline,
    mdiEyeOffOutline,
    mostrarSenha: false,
  }),
  computed: {
    ...mapGetters(["getAuth", "getLoginError", "getLoginLoading"]),
    form() {
      return {
        email: this.email.toLowerCase(),
        password: this.password,
      };
    },
  },

  methods: {
    ...mapActions(["loginUsuarioSenha"]),
    toggleMostrarSenha() {
      this.mostrarSenha = !this.mostrarSenha;
    },
    async login() {
      if (!this.$refs.form.validate()) return;
      let error = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.$refs[f].valid) {
          error = true;
          return;
        }
      });
      if (error) return;

      await this.loginUsuarioSenha({
        email: this.form.email,
        senha: this.form.password,
      });

      if (this.getLoginError) {
        this.$notify({
          title: "Erro",
          text: this.getLoginError,
          type: "error",
        });
        return;
      }

      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
.container-center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* #links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px 0 10px 0;
}
#recaptcha-container {
  display: flex;
  justify-content: center;
} */

a {
  text-decoration: none;
}
input .lowercase {
  text-transform: lowercase;
}
</style>
